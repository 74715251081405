.calendar > .day > .day {
    align-items: center;
    box-sizing: border-box;
    display: flex;
    font-size: 4rem;
    height: 100%;
    justify-content: center;
    opacity: 1;
    padding: 15px;
    position: absolute;
    transform: rotateY(0deg);
    transition: all 2s;
    width: 100%;
    z-index: 1;
}

.calendar > .day > .message {
    align-items: center;
    color: #000;
    display: flex;
    flex-flow: column;
    height: 100%;
    justify-content: center;
    opacity: 0;
    position: relative;
    transition: all 2s;
    transform: rotateY(180deg);
    z-index: 0;
    text-align: center;

    > div {
        width: 120px;
        position: absolute;
        transition: all 2s;
        &.question {
            opacity: 1;
        }
        &.answer {
            opacity: 0;
        }
    }
    
    &.turn > div {
        &.question {
            font-weight: 700;
            margin-bottom: 15px;
            position: relative;
        }
        &.answer {
            opacity: 1;
            position: relative;
        }
    }
}