.calendar > .day {
    background: rgba(175, 228, 242, 0.8);
    border: 1px solid rgb(22, 142, 204);
    cursor: pointer;
    box-sizing: border-box;
    min-height: 20vh;
    position: relative;

    &.open,
    &.isActive {
        > .day {
            opacity: 0;
            transform: rotateY(180deg);
            z-index: 0;
        }
        > .message {
            background: rgba(175, 228, 242, 0.8);
            color: #000;
            opacity: 1;
            transform: rotateY(0deg);
            z-index: 1;
        }
    }

    &.open .message {
        > .question {
            font-weight: 700;
            margin-bottom: 15px;
        }
        > .question,
        > .answer {
            display: block;
            opacity: 1;
            position: relative;
            transform: rotate(0);
        }
    }

    &.open .day {
        align-items: flex-start;
        color: #fff;
        display: flex;
        opacity: 1;
        position: absolute;
        transform: rotateY(0);
        top: 0;
        width: inherit;
        font-size: 2rem;
    }

    &.currentDay {
        background: rgb(32, 86, 127, 0.8);
        color: #fff;
    }

}

.day-01 {
    grid-area: day1;
}

.day-02 {
    grid-area: day2;
}

.day-03 {
    grid-area: day3;
}

.day-04 {
    grid-area: day4;
}

.day-05 {
    grid-area: day5;
}

.day-06 {
    grid-area: day6;
}

.day-07 {
    grid-area: day7;
}

.day-08 {
    grid-area: day8;
}

.day-09 {
    grid-area: day9;
}

.day-10 {
    grid-area: day10;
}

.day-11 {
    grid-area: day11;
}

.day-12 {
    grid-area: day12;
}

.day-13 {
    grid-area: day13;
}

.day-14 {
    grid-area: day14;
}

.day-15 {
    grid-area: day15;
}

.day-16 {
    grid-area: day16;
}

.day-17 {
    grid-area: day17;
}

.day-18 {
    grid-area: day18;
}

.day-19 {
    grid-area: day19;
}

.day-20 {
    grid-area: day20;
}

.day-21 {
    grid-area: day21;
}

.day-22 {
    grid-area: day22;
}

.day-23 {
    grid-area: day23;
}

.day-24 {
    grid-area: day24;
}

.day-3 {
    grid-area: day-3;
}