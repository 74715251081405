.calendar > .day {
    > .Special {
        opacity: 0;
        transition: opacity 2s;
    }
    &.isActive > .Special,
    &.open > .Special {
        bottom: -25px;
        opacity: 1;
        position: absolute;
        right: -15px;
        z-index: 2;
        @media screen and (min-width: 576px) {
            right: -40px;
        }
        img {
            width: 100px;
        }
        &.nicolaus img,
        &.christmas img {
            width: 175px;
        }
    }
    &.isActive > .Special.nicolaus,
    &.open > .Special.nicolaus {
        bottom: -3px;
        position: absolute;
        right: 0;
    }
    &.isActive > .Special.christmas,
    &.open > .Special.christmas {
        bottom: -15px;
        position: absolute;
        right: -15px;
    }

}

.Special > span {
    color: #fff;
    align-items: center;
    display: flex;
    font-size: 1.2rem;
    height: 100%;
    justify-content: center;
    margin-top: -5px;
    position: absolute;
    width: 100%;
}