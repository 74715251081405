.calendar {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-areas: 
        "day4"
        "day20"
        "day7"
        "day2"
        "day6"
        "day6"
        "day22"
        "day1"
        "day14"
        "day13"
        "day18"
        "day8"
        "day10"
        "day17"
        "day24"
        "day24"
        "day12"
        "day19"
        "day21"
        "day9"
        "day23"
        "day16"
        "day11"
        "day3"
        "day15"
        "day5"
        ;
    margin: 0 auto;
    padding: 15px;
    gap: 15px;

    .day-leer-1,
    .day-leer-2 {
        display: none;
    }
    
    @media screen and (min-width: 576px) {
        grid-template-columns: repeat(2, 1fr);
        grid-template-areas: 
            "day4 day20"
            "day7 day2"
            "day6 day6"
            "day22 day1"
            "day14 day13"
            "day18 day8"
            "day10 day17"
            "day24 day24"
            "day12 day19"
            "day21 day9"
            "day23 day16"
            "day11 day3"
            "day15 day5"
            ;
    }
    
    @media screen and (min-width: 768px) {
        grid-template-columns: repeat(3, 1fr);
        grid-template-areas: 
            "day4 day20 day7"
            "day2 day6 day6"
            "day22 day1 day14"
            "day13 day18 day8"
            "day10 day17 day12"
            "day24 day24 day19"
            "day21 day9 day23"
            "day16 day11 day3"
            "day15 day5 day-leer"
        ;
        width: calc((3 * 200px) + (3 * 15px));

        .day-leer-1 {
            display: block;
        }
    }

    @media screen and (min-width: 992px) {
        grid-template-columns: repeat(4, 1fr);
        width: 900px;
        grid-template-areas: 
            "day4 day20 day7 day2"
            "day6 day6 day22 day1"
            "day14 day13 day18 day8"
            "day10 day17 day12 day19"
            "day21 day24 day24 day9"
            "day23 day16 day11 day3"
            ". day15 day5 ."
        ;
        .day-leer-2 {
            display: block;
        }
    }
        
    @media screen and (min-width: 1300px) {
        grid-template-columns: repeat(6, 1fr);
        width: 1150px;
        grid-template-areas: 
            "day4 day20 day7 day6 day6 day2"
            "day22 day1 day14 day13 day18 day8"
            "day10 day17 day24 day24 day12 day19"
            "day21 day23 day24 day24 day16 day11"
            "leer-1 day9 day3 day15 day5 leer-2"
        ;
    }
}

.App {
    background-size: 150%;
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: fixed;
}
